.App {
  text-align: center;
  background-color: #fbfbfb;
  min-height: 100vh;
  color: #3333;
}
.App-container {
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Container{
  text-align: center;
}
.App-link {
  color: #61dafb;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-6{
    
    flex-basis: auto%;
}